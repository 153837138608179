const tableData = [
  {
    id: '1',
    country: 'Cameroun',
  },
  {
    id: '2',
    country: 'Nigeria',
  },{
    id: '3',
    country: 'Chad',
  },{
    id: '4',
    country: 'Ethopia',
  },{
    id: '5',
    country: 'Kenya',
  },{
    id: '6',
    country: 'Rwanda',
  },{
    id: '7',
    country: 'Belgium',
  },

];

export { tableData };
