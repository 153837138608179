<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <h4 class="card-title">All Countries</h4>
                            </div>
                            <div class="col-md-8">
                            </div>
                            <div class="col-md-2">
                                <b-button v-b-modal.modal-center variant="success">
                                    <i class="ri-add-line  align-middle mr-2"></i> Add country
                                </b-button>
                                <b-modal
                                        id="modal-center"
                                        centered
                                        title="Add Country"
                                        title-class="font-18"
                                        ref="my-modal"
                                        hide-footer

                                >
                                    <form @submit.prevent="createCountry">
                                        <label >Select Country</label>
                                        <multiselect v-model="country" :options="options"  @select="selectedCountry" :clear-on-select="true" :multiple="false" placeholder="Select countries"></multiselect>
                                        <div class="form-group" style="marginTop: 10px">
                                            <label >Prefix</label>
                                            <input
                                                    v-model="prefix"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Country prefix"
                                            />
                                        </div>
                                        <button
                                                class="btn btn-primary w-md waves-effect waves-light mt-4"
                                                type="submit"
                                                @click="hideModal"
                                        >Save</button>
                                    </form>

                                </b-modal>
                            </div>
                            <b-modal title="Edit Country" ref="modal-delete" id="modal-delete" hide-footer v-if="deleteT">
                                <div class="form-group">
                                    <label >Name</label>
                                    <input
                                            v-model="deleteT.country_name"
                                            type="text"
                                            class="form-control"
                                            placeholder="Country name"
                                    />
                                </div>
                                <div class="form-group">
                                    <label >Prefix</label>
                                    <input
                                        v-model="deleteT.country_prefix"
                                            type="text"
                                            class="form-control"
                                            placeholder="Country prefix"
                                    />
                                </div>
                                <div style="display: flex; justify-content: end; align-items: center; margin-top: 1rem">
                                    <b-button variant="primary" style="margin-right: .5rem" @click.prevent="hidemodal">Cancel</b-button>
                                    <b-button variant="success" @click.prevent="del">Update</b-button>
                                </div>
                            </b-modal>

                        </div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="countryData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                            >

                                <template v-slot:cell(actions)="{item}">
<!--                                    <div class="row justify-content-end">-->
                                        <b-button v-b-tooltip.hover title="Delete" v-b-modal.modal-delete class="actionBut" @click="deleteCount(item)" variant="success">
                                            <i class="ri-edit-fill align-middle "></i>
                                        </b-button>

<!--                                    </div>-->
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="lodader__content" v-if="loader">
                            <b-spinner style="width: 3rem; height: 3rem" class="loader" variant="success" label="Spinning"></b-spinner>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </Layout>

</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Multiselect from "vue-multiselect"
    import {axios_get, axios_post, axios_put} from "../../../helpers/helper";
    import { tableData } from "./dataCountries";

    /**
     * Advanced table component
     */
    export default {
        page: {
            title: "View Countries",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout, PageHeader ,Multiselect},
        data() {
            return {
                countr:[],
                countryData: [],
                country_name:null,
                prefix:null,
                deleteT:null,
                tableData: tableData,
                loader: false,
                title: "View supported countries",
                items: [
                    {
                        text: "Dashboard",
                        href: "/"
                    },
                    {
                        text: "View Countries",
                        active: true
                    }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "age",
                sortDesc: false,
                fields: [
                    { key: "country_id", sortable: true },
                    { key: "country_name", sortable: true },
                    { key: "country_prefix", sortable: true },
                    { key: "actions", sortable: false }
                ],
                value:null,

                country:"",
                options: [
                    "Afghanistan",
                    "Åland Islands",
                    "Albania",
                    "Algeria",
                    "American Samoa",
                    "Andorra",
                    "Angola",
                    "Anguilla",
                    "Antarctica",
                    "Antigua and Barbuda",
                    "Argentina",
                    "Armenia",
                    "Aruba",
                    "Australia",
                    "Austria",
                    "Azerbaijan",
                    "Bahamas (the)",
                    "Bahrain",
                    "Bangladesh",
                    "Barbados",
                    "Belarus",
                    "Belgium",
                    "Belize",
                    "Benin",
                    "Bermuda",
                    "Bhutan",
                    "Bolivia (Plurinational State of)",
                    "Bonaire, Sint Eustatius and Saba",
                    "Bosnia and Herzegovina",
                    "Botswana",
                    "Bouvet Island",
                    "Brazil",
                    "British Indian Ocean Territory (the)",
                    "Brunei Darussalam",
                    "Bulgaria",
                    "Burkina Faso",
                    "Burundi",
                    "Cabo Verde",
                    "Cambodia",
                    "Cameroon",
                    "Canada",
                    "Cayman Islands (the)",
                    "Central African Republic (the)",
                    "Chad",
                    "Chile",
                    "China",
                    "Christmas Island",
                    "Cocos (Keeling) Islands (the)",
                    "Colombia",
                    "Comoros (the)",
                    "Congo (the Democratic Republic of the)",
                    "Congo (the)",
                    "Cook Islands (the)",
                    "Costa Rica",
                    "Croatia",
                    "Cuba",
                    "Curaçao",
                    "Cyprus",
                    "Czechia",
                    "Côte d'Ivoire",
                    "Denmark",
                    "Djibouti",
                    "Dominica",
                    "Dominican Republic (the)",
                    "Ecuador",
                    "Egypt",
                    "El Salvador",
                    "Equatorial Guinea",
                    "Eritrea",
                    "Estonia",
                    "Eswatini",
                    "Ethiopia",
                    "Falkland Islands (the) [Malvinas]",
                    "Faroe Islands (the)",
                    "Fiji",
                    "Finland",
                    "France",
                    "French Guiana",
                    "French Polynesia",
                    "French Southern Territories (the)",
                    "Gabon",
                    "Gambia (the)",
                    "Georgia",
                    "Germany",
                    "Ghana",
                    "Gibraltar",
                    "Greece",
                    "Greenland",
                    "Grenada",
                    "Guadeloupe",
                    "Guam",
                    "Guatemala",
                    "Guernsey",
                    "Guinea",
                    "Guinea-Bissau",
                    "Guyana",
                    "Haiti",
                    "Heard Island and McDonald Islands",
                    "Holy See (the)",
                    "Honduras",
                    "Hong Kong",
                    "Hungary",
                    "Iceland",
                    "India",
                    "Indonesia",
                    "Iran (Islamic Republic of)",
                    "Iraq",
                    "Ireland",
                    "Isle of Man",
                    "Israel",
                    "Italy",
                    "Jamaica",
                    "Japan",
                    "Jersey",
                    "Jordan",
                    "Kazakhstan",
                    "Kenya",
                    "Kiribati",
                    "Korea (the Democratic People's Republic of)",
                    "Korea (the Republic of)",
                    "Kuwait",
                    "Kyrgyzstan",
                    "Lao People's Democratic Republic (the)",
                    "Latvia",
                    "Lebanon",
                    "Lesotho",
                    "Liberia",
                    "Libya",
                    "Liechtenstein",
                    "Lithuania",
                    "Luxembourg",
                    "Macao",
                    "Madagascar",
                    "Malawi",
                    "Malaysia",
                    "Maldives",
                    "Mali",
                    "Malta",
                    "Marshall Islands (the)",
                    "Martinique",
                    "Mauritania",
                    "Mauritius",
                    "Mayotte",
                    "Mexico",
                    "Micronesia (Federated States of)",
                    "Moldova (the Republic of)",
                    "Monaco",
                    "Mongolia",
                    "Montenegro",
                    "Montserrat",
                    "Morocco",
                    "Mozambique",
                    "Myanmar",
                    "Namibia",
                    "Nauru",
                    "Nepal",
                    "Netherlands (the)",
                    "New Caledonia",
                    "New Zealand",
                    "Nicaragua",
                    "Niger (the)",
                    "Nigeria",
                    "Niue",
                    "Norfolk Island",
                    "Northern Mariana Islands (the)",
                    "Norway",
                    "Oman",
                    "Pakistan",
                    "Palau",
                    "Palestine, State of",
                    "Panama",
                    "Papua New Guinea",
                    "Paraguay",
                    "Peru",
                    "Philippines (the)",
                    "Pitcairn",
                    "Poland",
                    "Portugal",
                    "Puerto Rico",
                    "Qatar",
                    "Republic of North Macedonia",
                    "Romania",
                    "Russian Federation (the)",
                    "Rwanda",
                    "Réunion",
                    "Saint Barthélemy",
                    "Saint Helena, Ascension and Tristan da Cunha",
                    "Saint Kitts and Nevis",
                    "Saint Lucia",
                    "Saint Martin (French part)",
                    "Saint Pierre and Miquelon",
                    "Saint Vincent and the Grenadines",
                    "Samoa",
                    "San Marino",
                    "Sao Tome and Principe",
                    "Saudi Arabia",
                    "Senegal",
                    "Serbia",
                    "Seychelles",
                    "Sierra Leone",
                    "Singapore",
                    "Sint Maarten (Dutch part)",
                    "Slovakia",
                    "Slovenia",
                    "Solomon Islands",
                    "Somalia",
                    "South Africa",
                    "South Georgia and the South Sandwich Islands",
                    "South Sudan",
                    "Spain",
                    "Sri Lanka",
                    "Sudan (the)",
                    "Suriname",
                    "Svalbard and Jan Mayen",
                    "Sweden",
                    "Switzerland",
                    "Syrian Arab Republic",
                    "Taiwan (Province of China)",
                    "Tajikistan",
                    "Tanzania, United Republic of",
                    "Thailand",
                    "Timor-Leste",
                    "Togo",
                    "Tokelau",
                    "Tonga",
                    "Trinidad and Tobago",
                    "Tunisia",
                    "Turkey",
                    "Turkmenistan",
                    "Turks and Caicos Islands (the)",
                    "Tuvalu",
                    "Uganda",
                    "Ukraine",
                    "United Arab Emirates (the)",
                    "United Kingdom of Great Britain and Northern Ireland (the)",
                    "United States Minor Outlying Islands (the)",
                    "United States of America (the)",
                    "Uruguay",
                    "Uzbekistan",
                    "Vanuatu",
                    "Venezuela (Bolivarian Republic of)",
                    "Viet Nam",
                    "Virgin Islands (British)",
                    "Virgin Islands (U.S.)",
                    "Wallis and Futuna",
                    "Western Sahara",
                    "Yemen",
                    "Zambia",
                    "Zimbabwe",
                ],
            };
        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.tableData.length;
            }
        },
        mounted(){
            // Set the initial number of items
            this.totalRows = this.items.length;
            axios_get("/country",{},(d)=>{
                console.log(d)
                this.countryData=d
                },(e)=>{
                    console.log("error",e);
                },()=>{
                    console.log("finishing");
                }
            );


        },
        methods: {
            deleteCount(e){
                this.deleteT = e
            },
            selectedCountry(country_name){
                this.country_name=country_name
            },
            hidemodal(){
                this.$refs['modal-delete'].hide()
            },
            createCountry(){
                this.loader = true
                axios_post("/country",{
                    country_name:this.country_name,
                    country_prefix:this.prefix,
                },(d)=>{
                    axios_get("/country",{},(d)=>{
                            this.countryData=(d)
                            localStorage.setItem('country',JSON.stringify(d))
                            window.location.reload()
                        },(e)=>{
                            console.log("error",e);
                        },()=>{
                            console.log("finishing");
                            this.loader = false
                        }
                    );
                    console.log(d);
                },(e)=>{
                    console.log("error",e);
                },()=>{
                    console.log("finishing");
                    this.loader = false
                })
            },
            del(){
                console.log(this.deleteT)
                this.hidemodal()
                this.loader = true
                axios_put("/country/"+this.deleteT.country_id,this.deleteT,
                    (d)=>{
                        console.log(d)
                        axios_get("/country",{},(d)=>{
                                this.countryData=d
                                localStorage.setItem('country',JSON.stringify(d))
                                // window.location.reload()
                            },(e)=>{
                                console.log("error",e);
                            },()=>{
                                this.loader = false
                                console.log("finishing");
                            }
                        );
                        window.makeToast('Success','Country Updated Successfully','success')
                    },(e)=>{
                        console.log("error",e);
                    },()=>{
                        this.loader = false
                        console.log("finishing");
                    }
                );


            },
            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            hideModal() {
                this.$refs['my-modal'].hide()
            },
        }
    };
</script>

<style scoped>
    .actionBut{
        margin-left:15px;
    }

    .lodader__content{
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .1);
        width: 100%;
        height: 100%;
    }

    .loader{
        position: relative;
        left: 50%;
        top: 50%;
    }
</style>